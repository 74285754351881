"use client";
import React from "react";
import { useTranslations } from 'next-intl';
import Lottie from "lottie-react";
import hexGraphic from '@/components/animations/hexagon-loading-alt.json';

const LoadingAnimation = () => {
  const t = useTranslations('Common');

  return (
    <>
      <div className="bg-white w-full h-screen flex flex-col items-center justify-center colmena-rounded">
        <div className="h-48 w-48 mx-auto">
          <Lottie animationData={hexGraphic} loop={true} className='object-contain' />
        </div>
        <p className="font-bold text-brand-blue-mid">{t('loading')}</p>
      </div>
      <div className="h-24 w-full bg-gradient-to-b from-brand-blue-dark to-white" />
    </>
  );
};

export default LoadingAnimation;

